.mapmode-dropdown {
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    position: relative;
    top: 50%;
    width: 92px;
    .dropdown {
        display: inline-block;
        .selected, .option {
            transform: translateY(-50%);
            padding: 2px;
        }
        .option {
            display: none;
            background: #020E26;
        }
        &:hover {
            cursor: pointer;
            .option {
                display: block;
                z-index: 50000;
                &:hover {
                    background: #2759bc;
                }
            }
            .option.current {
                background: #4c6faa;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    
}