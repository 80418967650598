@import "../../global.scss";

$bg-color: rgba(240, 250, 250, 0.9);

.sbox {
  position: relative;
  background-color: $bg-color;
  border: 2px solid $background;
  margin-bottom: 2px;
  min-height: 20px;
}

.sts {
  position: relative;
  margin-left: 8px;
  margin-top: 2px;
  z-index: 500;
  overflow-y: scroll;

  .sts-list {
    position: relative;
    .sts-empty {
      position: relative;
      svg {
        fill: dimgray;
        width: 60px;
        height: 60px;
        margin-top: 8px;
        margin-left: 50%;
        transform: translateX(-50%);
      }
      div {
        width: 100%;
        text-align: center;
        margin-bottom: 8px;
      }
    }
    .sts-results {
      position: relative;
      .sts-block {
        position: relative;
        margin: 4px 8px;
        .sts-top {
          height: 48px;
          .sts-item {
            float: left;
          }
          .sts-type {
            font-size: 12px;
            color: grey;
          }
          .sts-name {
            font-size: 16px;
            font-weight: bold;
            user-select: none;
            cursor: pointer;
            i {
              margin-left: 8px;
              font-size: 14px;
              color: grey;
            }
          }
          .risk-level {
            position: absolute;
            top: 2px;
            right: 2px;
            width: 80px;
            .risk-circle {
              margin-left: 50%;
              transform: translateX(-50%);
              width: 28px;
              height: 28px;
              border-radius: 28px;
              text-align: center;
              line-height: 28px;
              color: white;
            }
            .risk-text {
              width: 100%;
              text-align: center;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
        .sts-bottom {
          border-top: 1px solid lightgrey;
          .sts-info {
            font-size: 14px;
            margin-top: 4px;
          }
        }
        .sts-block-entity {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          .sts-block-left {
            flex: 1;
          }
          .sts-block-right {
            width: 100px;
            margin-left: 4px;
            .sts-entity-img {
              margin-top: 6px;
              width: 100%;
              border: 2px solid lightgrey;
            }
          }
        }
      }
      .sts-news-title {
        width: 100%;
        height: 100%;
        padding: 4px 8px;
      }
    }
  }
}