@import "../../global.scss";
.algorithm {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .al_inner {
    position: relative;
    padding: 24px 20%;
    margin-bottom: 68px;
    .al {
      position: relative;
      font-size: 18px;
      color: $background;
      margin-left: 50%;
      text-align: center;
      transform: translateX(-50%);
      margin-top: 20px;
      margin-bottom: 20px;
      max-width: 480px;
    }
    h1 {
      font-size: 36px;
      font-weight: bold;
      color: $background; 
      margin-top: 24px;
      margin-bottom: 48px;
    }
    h2 {
      font-size: 24px;
      font-weight: bold;
      color: $background;
      margin-top: 32px;
    }
    h3 {
      text-indent: 2em;
    }
    img {
      position: relative;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-top: 12px;
      margin-bottom: 18px;
    }
  }
  .footer {
    height: 42px;
    background-color: #020e25;
    color: white;
    text-align: center;
    line-height: 40px;
  }
}

@media screen and (max-width: 560px) {
  .algorithm {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .al_inner {
      position: relative;
      padding: 24px 6%;
      margin-bottom: 48px;
      .al {
        position: relative;
        font-size: 14px;
        width: 300px;
      }
      h1 {
        font-size: 26px;
        font-weight: bold;
        color: $background; 
        margin-top: 24px;
        margin-bottom: 42px;
      }
      h2 {
        font-size: 22px;
        font-weight: bold;
        color: $background;
        margin-top: 18px;
        margin-bottom: 6px;
      }
      h3 {
        text-indent: 2em;
      }
      img {
        position: relative;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 8px;
        margin-bottom: 12px;
      }
    }
  }
}