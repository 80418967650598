@import "../../global.scss";

.searchbar {
  position: relative;

  .input {
    margin: 2px 2px;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    font-weight: bold;
    color: $background;
    font-size: 14px;
  }
  .svg {
    position: absolute;
    left: 6px;
    top: 4px;
    fill: $background;
    width: 26px;
    height: 26px;
    transition: all 0.2s linear;
    cursor: pointer;

    &:hover {
      fill: grey;
    }
  }
  .close {
    position: absolute;
    top: 4px;
    right: -30px;
    width: 26px;
    height: 26px;
    fill: dimgray;
    opacity: 0.6;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  .hints {
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    width: 100%;
    background-color:white;
    border-radius: 4px;
    border: 1px solid $background;
    z-index: 1100;

    .hint_entity {
      padding: 0 8px;
      border-bottom: 1px solid rgb(231, 231, 231);
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      user-select: none;
      cursor: pointer;
    }
  }
}