@import "../../global.scss";

.eventpanel {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff01;
    z-index: 1000;

    .events_header {
        color: $background;
        font-size: 14px;
        line-height: 36px;
        padding-left: 8px;
        font-weight: bold;
        height: 36px;
        width: 100%;
        border-bottom: 1px solid grey;
    }

    .panel {
        position: absolute;
        left: 40%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 64%;
        height: 60%;
        border-radius: 4px;
        background-color: rgba(202, 212, 226, 0.945);
        display: flex;
        border: 4px solid $background;
        flex-direction: row;
        .left {
            flex: 30;
            width: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            user-select: none;
            .list {
                width: 100%;
                flex: 1;
                height: 0;
                overflow-y: scroll;
                padding: 0 6px;
                .list_inner {
                    width: 100%;
                    .event_title {
                        color: $background;
                        font-size: 14px;
                        margin-top: 4px;
                        margin-bottom: 4px;
                        font-weight: bold;
                        background-color: rgba(162, 211, 208, 0.952);
                        border-radius: 4px;
                        padding: 2px 4px;
                        cursor: pointer;
                    }
                }
            }
        }
        .mid {
            position: relative;
            flex: 46;
            width: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
            border-left: 2px solid grey;

            .title {
                font-size: 16px;
                line-height: 24px;
                font-weight: bold;
                padding: 12px 12px;
                color: $background;
            }

            .content_outter {
                position: relative;
                flex: 1;
                width: calc(100% + 14px);
                height: 0;
                overflow-y: scroll;
                border-top: 1px solid grey;
                .content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    white-space: pre-wrap;
                    padding: 12px 12px;
                    font-size: 14px;
                    width: calc(100% - 14px);
                    text-align: left;
                    color: $background;
                }
                .paper_line {
                    width: 98%;
                    padding: 2px 12px;
                    color: $background;
                    font-size: 14px;
                    font-weight: bold;
                    .paper_content {
                        font-weight: normal;
                        color: $background;
                        font-style: italic;
                    }
                }
            }
        }

        .right {
            position: relative;
            flex: 24;
            width: 0;
            height: 100%;
            border-left: 2px solid grey;
            display: flex;
            flex-direction: column;
            .list {
                position: relative;
                width: 100%;
                flex: 1;
                height: 0;
                overflow-y: scroll;
                .entities {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .entity {
                        position: relative;
                        font-size: 14px;
                        overflow: hidden;
                        text-align: left;
                        padding: 2px 6px;
                        padding-right: 36px;
                        width: fit-content;
                        margin: 4px 0 4px 6px;
                        background-color: rgb(186, 236, 252);
                        border-radius: 4px;
                        user-select: none;
                        cursor: pointer;
                        .entityflag {
                            position: absolute;
                            right: -6px;
                            top: 3px;
                        }
                    }
                }
                
            }
            .related-events {
                position: relative;
                width: 100%;
                height: 50%;
                padding: 2px 0px;
                margin-top: 4px;
                border-top: 1px solid grey;
                display: flex;
                flex-direction: column;
                .list {
                    flex: 1;
                    width: 100%;
                    height: 0;
                    overflow-y: scroll;
                    padding: 0 8px;
                    .related-event {
                        .title {
                            font-size: 14px;
                            font-weight: 500;
                        }
                        .date {
                            font-size: 14px;
                            font-weight: 500;
                            margin-right: 5px;
                        }
                        .type {
                            font-size: 12px;
                            font-weight: 500;
                            padding: 2px 3px;
                            border-radius: 5px;
                        }
                        &:hover {
                            background: #ffffff80;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .eventpanel {
        .panel {
            position: absolute;
            left: 0;
            top: unset;
            bottom: 0;
            transform: translate(0, 0);
            width: 100%;
            height: 86%;
            border-radius: 0px;
            background-color: rgba(202, 212, 226, 0.945);
            display: block;
            border: 1px solid $background;
            .left {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 40%;
                display: flex;
                flex-direction: column;
                user-select: none;
                border-top: 2px solid grey;
                .events_header {
                    color: $background;
                    font-size: 14px;
                    line-height: 32px;
                    padding-left: 8px;
                    font-weight: bold;
                    height: 32px;
                    width: 100%;
                    border-bottom: 1px solid grey;
                }
                .list {
                    width: 100%;
                    flex: 1;
                    height: auto;
                    overflow-y: scroll;
                    padding: 0 6px;
                    .list_inner {
                        width: 100%;
                        .event_title {
                            color: $background;
                            font-size: 14px;
                            margin-top: 4px;
                            margin-bottom: 4px;
                            font-weight: bold;
                            background-color: rgba(162, 211, 208, 0.952);
                            border-radius: 4px;
                            padding: 2px 4px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .mid {
                position: absolute;
                left: 0;
                top: 0;
                width: 54%;
                height: 60%;
                display: flex;
                flex-direction: column;
                overflow-x: hidden;
                border-left: 2px solid grey;

                .title {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: bold;
                    padding: 12px 12px;
                    color: $background;
                }

                .content_outter {
                    position: relative;
                    flex: 1;
                    width: calc(100% + 14px);
                    height: 0;
                    overflow-y: scroll;
                    border-top: 1px solid grey;
                    .content {
                        position: absolute;
                        top: 0;
                        left: 0;
                        white-space: pre-wrap;
                        padding: 12px 12px;
                        font-size: 14px;
                        width: calc(100% - 14px);
                        text-align: left;
                        color: $background;
                    }
                    .paper_line {
                        width: 98%;
                        padding: 2px 12px;
                        color: $background;
                        font-size: 14px;
                        font-weight: bold;
                        .paper_content {
                            font-weight: normal;
                            color: $background;
                            font-style: italic;
                        }
                    }
                }
            }

            .right {
                position: absolute;
                right: 0;
                top: 0;
                width: 46%;
                height: 60%;
                border-left: 2px solid grey;
                display: flex;
                flex-direction: column;

                .events_header {
                    color: $background;
                    font-size: 14px;
                    line-height: 28px;
                    padding-left: 8px;
                    font-weight: bold;
                    height: 28px;
                    width: 100%;
                    border-bottom: 1px solid grey;
                }
                .list {
                    width: 100%;
                    flex: 1;
                    height: 0;
                    padding: 0 4px;
                    overflow-y: scroll;
                    .entity {
                        margin-top: 4px;
                        margin-bottom: 4px;
                        background-color: rgb(186, 236, 252);
                        box-sizing: border-box;
                        .label {
                            padding: 4px;
                            font-size: 16px;
                            font-weight: bold;
                            width: 100%;
                        }
                        .content {
                            padding: 4px;
                            font-size: 12px;
                            max-height: 116px;
                            overflow-y: hidden;
                            text-overflow: ellipsis;

                            a {
                                color: black;
                                pointer-events: none;
                                touch-action: none;
                            }
                        }
                    }
                }
            }
        }
    }
}