.toolbar {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .toolbar_langall {
        float: left;
        margin: 8px 8px;
        .toolbar_showall {
            color: white;
            font-size: 14px;
            font-weight: bold;
            margin-right: 6px;
        }
    }

    .toolbar_locale {
        float: right;
        margin-top: 4px;
        margin-right: 18px;
        svg {
            margin-top: 2px;
            width: 32px;
            height: 32px;
        }
    }

    .toolbar_source {
        float: right;
        margin-top: 6px;
        margin-right: 12px;
        padding: 4px 2px 0 2px;
        background-color: rgba(211, 211, 211, 0.849);
        border-radius: 4px;
        svg {
            width: 24px;
            height: 20px;
            fill: #020E26;
        }
    }

    .toolbar_source:hover {
        opacity: 0.8;
    }

    .button {
        float: right;
        font-size: 24px;
        margin-top: 2px;
        margin-right: 15px;
        transition: transform .2s;
        &:hover {
            cursor: pointer;
            transform: scale(1.2);
        }
    }
    .button.light {
        color: #dddddd;
    }
    .button.dark {
        color: #222222;
    }
}

@media screen and (max-width: 560px) {
    .toolbar {
        .toolbar_locale {
            float: right;
            margin-top: 4px;
            margin-right: 8px;
        }

        .toolbar_source {
            display: none;
        }
    }
}