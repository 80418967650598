.source {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #020E2611;

    .source_panel {
        position: relative;
        width: 480px;
        height: 60%;
        margin-top: 6%;
        margin-left: calc(40% - 240px);
        background-color: #020E26dd;
        border-radius: 10px;

        .source_content {
            position: absolute;
            left: 0;
            top: 0;
            width: 80%;
            height: 90%;
            margin: 5% 10%;
            overflow-x: hidden;
            overflow-y: scroll;

            .title {
                font-size: 24px;
                color: white;
                font-weight: bold;
                margin-bottom: 12px;
            }

            .type {
                margin-bottom: 18px;
                .type_name {
                    font-size: 18px;
                    color: white;
                    margin-bottom: 6px;
                }
                .item {
                    text-indent: 2em;
                    font-size: 16px;
                    line-height: 24px;
                    a {
                        color: rgb(139, 200, 241)
                    }
                }
            }
        }

        .source_close {
            position: absolute;
            top: 12px;
            right: 12px;
            width: 32px;
            height: 32px;
            svg {
                width: 100%;
                height: 100%;
                fill: lightgrey;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .source {
        z-index: 50;
        .source_panel {
            position: relative;
            width: 100%;
            height: 100%;
            margin-top: 0;
            margin-left: 0;
            border-radius: 0;
    
            .source_content {
                position: absolute;
                left: 0;
                top: 0;
                width: 80%;
                height: 90%;
                margin: 5% 10%;
                overflow-x: hidden;
                overflow-y: scroll;
    
                .title {
                    font-size: 24px;
                    color: white;
                    font-weight: bold;
                    margin-bottom: 24px;
                }
            }
    
            .source_close {
                position: absolute;
                top: 12px;
                right: 12px;
                width: 32px;
                height: 32px;
                svg {
                    width: 100%;
                    height: 100%;
                    fill: lightgrey;
                }
            }
        }
    }
}