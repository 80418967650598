.inf {
  background-color: grey;
  border-radius: 4px;
  padding: 0px 4px;
  color: white;
  user-select: none;
  margin: 0 2px;
  i {
    color: springgreen;
    margin-right: 2px;
  }
}