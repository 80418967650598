.epidemic-map {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // background: #2b3a57;
    background: #F6E6CB40;
}
