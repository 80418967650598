.App {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  min-height: 520px;
  overflow: hidden;
}

@media screen and (max-width: 560px) {
  .App {
    min-width: 300px;
    min-height: 100px;
  }   
}