@import "../../global.scss";

.hotbar {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .hot {
    position: relative;
    flex: 1;
    width: 100%;
    height: 0;
    margin-bottom: 2px;
    display: flex;
    flex-direction: row;
    .bar {
      position: relative;
      height: 100%;
      width: 24px;
      background-color: $background;
      user-select: none;
      cursor: pointer;
      .title {
        position: absolute;
        left: 0;
        top: -10px;
        white-space: nowrap;
        color: gold;
        font-weight: bold;
        transform: rotate(90deg);
        transform-origin: left bottom;
      }
    }
    .con {
      position: relative;
      height: 100%;
      width: 180px;
      .con_inner {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 2px 4px;
        overflow-y: scroll;
        transition: all 0.3s linear;
        opacity: 0.8;
        &:hover {
          opacity: 1;
          background-color: #020E26aa;
          .event {
            .title {
              color: gold;
              text-shadow: none;
            }
          }
        }
        .event {
          line-height: 16px;
          margin-bottom: 6px;
          user-select: none;
          cursor: pointer;
          .title {
            font-size: 12px;
            color: #020E26;
            text-shadow: 1px 1px 2px white;
          }
        }
        .entities {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .entity {
            padding: 2px 6px;
            background-color: $entity-background;
            border-radius: 4px;
            margin-right: 4px;
            margin-bottom: 4px;
            font-size: 12px;
            color: #020E26;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}