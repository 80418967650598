$avatar-width: 140px;

.contributors {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  .content {
    position: relative;
    width: 68%;
    min-width: 720px;
    margin: 0 auto 32px auto;
    padding: 0 12px;

    .title {
      font-size: 42px;
      font-weight: bold;
      margin: 48px 0 0px 0;
      line-height: 52px;
      color: black;
    }

    .subtitle {
      font-size: 14px;
    }

    .list {
      margin-top: 42px;

      .org {
        margin-top: 12px;

        .org_name {
          padding-left: 12px;
          font-size: 22px;
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 1px solid lightgrey;
        }

        .members {
          width: 100%;
          margin-top: 24px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .member {
            margin: 0 12px;
            width: 180px;
            height: 214px;

            .member_avatar {
              width: $avatar-width;
              height: $avatar-width;
              overflow: hidden;
              border-radius: $avatar-width;
              margin: 0 auto;
              border: 2px solid lightgrey;

              img {
                width: 100%;
              }
            }

            .member_name {
              width: 100%;
              text-align: center;
              font-size: 16px;
              margin-top: 6px;
              color: #020e25;
            }
          }
        }
      }
    }
  }
  .footer {
    height: 42px;
    background-color: #020e25;
    color: white;
    text-align: center;
    line-height: 40px;
  }
}

@media screen and (max-width: 560px) {
  $avatar-width: 90px;

  .contributors {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .content {
      position: relative;
      width: 100%;
      min-width: unset;
      margin: 0 auto 32px auto;
      padding: 0 12px;

      .title {
        font-size: 32px;
        font-weight: bold;
        margin: 32px 0 0px 0;
        line-height: 36px;
        color: black;
      }

      .subtitle {
        font-size: 12px;
      }

      .list {
        margin-top: 32px;

        .org {
          margin-top: 8px;
          .org_name {
            padding-left: 8px;
            font-size: 16px;
            width: 100%;
            padding-bottom: 8px;
            border-bottom: 1px solid lightgrey;
          }

          .members {
            width: 100%;
            margin-top: 24px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .member {
              margin: 0 12px;
              width: 140px;
              height: 154px;

              .member_avatar {
                width: $avatar-width;
                height: $avatar-width;
                overflow: hidden;
                border-radius: $avatar-width;
                margin: 0 auto;
                border: 2px solid lightgrey;

                img {
                  width: 100%;
                }
              }

              .member_name {
                width: 100%;
                text-align: center;
                font-size: 16px;
                margin-top: 6px;
                color: #020e25;
              }
            }
          }
        }
      }
    }
  }
}