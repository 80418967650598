@import "../../global.scss";
$main-right-min-width: 300px;
$main-margin: 6px;

.main {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .main_root {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .main_header {
            position: relative;
        }

        .main_content {
            position: relative;
            width: 100%;
            height: 0;
            flex: 1;
            .main_map {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            .main_upper {

                .event-tree {
                    max-width: calc(100% - #{$main-right-min-width} - #{$main-margin} * 3);
                    width: calc(80% - #{$main-margin} * 3);
                    margin-left: $main-margin;
                    bottom: calc(12px + 20% + #{$main-margin} * 2 - #{$main-margin} * 2 + 90px);
                    position: absolute;
                }

                .main_hotbar {
                    position: absolute;
                    left: 0px;
                    top: 160px;
                    height: 50%;
                }

                .main_timeline {
                    position: absolute;
                    bottom: 12px;
                    max-width: calc(100% - #{$main-right-min-width} - #{$main-margin} * 3);
                    width: calc(80% - #{$main-margin} * 3);
                    margin-left: $main-margin;
                    transition: height linear 0.2s;
                    overflow-y: visible;
                }

                .main_controlbar {
                    position: absolute;
                    left: $main-margin;
                    bottom: calc(12px + 20% + #{$main-margin} * 2 - #{$main-margin} * 2);
                }

                .main_right {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 20%;
                    min-width: $main-right-min-width;
                    height: 98%;
                    margin-top: $main-margin;
                    margin-right: $main-margin;
                    display: flex;
                    flex-direction: column;

                    .main_toolbar {
                        position: relative;
                        width: 100%;
                        height: 42px;
                        background-color: $background;
                    }

                    .main_infobar {
                        position: relative;
                        width: 100%;
                        margin-top: $main-margin;
                        background-color: $background;
                    }

                    .main_forcast {
                        position: relative;
                        width: 100%;
                        margin-top: $main-margin;
                        height: 0;
                        flex: 1;
                        background-color: $background;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    $btn-width: 30px;
    .main {
        margin-top: 0px;
        .main_root {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .main_header {
                position: relative;
            }

            .main_content {
                position: relative;
                width: 100%;
                height: 0;
                flex: 1;
                .main_upper {
                    .main_timeline {
                        position: absolute;
                        bottom: 12px;
                        min-height: 0px;
                        max-width: 100%;
                        width: 100%;
                        margin-left: 0;
                    }

                    .main_right {
                        position: unset;
                        right: 0;
                        top: 0;
                        width: unset;
                        min-width: $main-right-min-width;
                        height: unset;
                        margin-top: $main-margin;
                        margin-right: $main-margin;
                        display: block;

                        .main_toolbar {
                            display: none;
                        }
                        .main_btns {
                            position: absolute;
                            max-width: 35%;
                            right: $main-margin;
                            top: $main-margin;
                            .btn_svg {
                                float: right;
                                width: $btn-width;
                                height: $btn-width;
                                padding: 4px;
                                background-color: $background;
                                margin-left: $main-margin;
                                margin-bottom: $main-margin;
                                border-radius: 4px;
                                svg {
                                    fill: lightgrey;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
                .main_forcast {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(2, 14, 38, 0.9);
                    z-index: 50;
                }

                .main_controlbar {
                    position: absolute;
                    left: 0;
                    top: 100px;
                    bottom: unset;
                }
            }
        }
    }
}